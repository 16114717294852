import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
    console.log(data)
    return (
        <Layout>
            <div>
              
                <h4>{data.allWordpressPost.edges.length} Posts</h4>
                {data.allWordpressPost.edges.map(({ node }) => (
                <div key={node.id}>
                    <h3>
                    {node.title}{" "}
                    <span>
                        {/* — {node.frontmatter.date} */}
                    </span>
                    </h3>
                    <p>
                    {node.excerpt}
                    </p>
                </div>
                ))}
            </div>
        </Layout>
    )
}

export const query = graphql`
{ 
    allWordpressPost {
      edges {
        node {
          id
          date
          slug
          link
          title
          content
          excerpt
        }
      }
    }
    }
    
`